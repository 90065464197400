import { useMemo } from "react";

export const useQueryString = <T,>() => {
  const result = useMemo(() => {
    const params: { [id: string]: string } = {};

    new URLSearchParams(window.location.search).forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }, []);

  return result as T;
}