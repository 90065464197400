import { Button, Form, Input } from "antd";
import { gql, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import styled from "styled-components";
import validator from "validator";

import { useQueryString } from "./hooks/query";
import { useArtistRelease } from "./hooks/artist_release";

export const App = () => {
  const [subscribed, setSubscribed] = useState(false);
  const [log, setLog] = useState("");

  const {
    artist_id: artistId,
    platform,
    platform_id: platformId,
  } = useQueryString<{
    artist_id: string;
    platform: string;
    platform_id: string;
  }>();
  const artistRelease = useArtistRelease(artistId);

  const [subscribeArtist] = useMutation(
    gql`
      mutation SubscribeArtist(
        $artistId: String!
        $platform: String!
        $platformId: String!
        $emailAddress: String!
      ) {
        subscribeArtist(
          artistId: $artistId
          platform: $platform
          platformId: $platformId
          emailAddress: $emailAddress
        ) {
          success
        }
      }
    `
  );

  const [subscribe, { data, error }] = useMutation(
    gql`
      mutation Subscribe(
        $platform: String!
        $platformId: String!
        $emailAddress: String!
      ) {
        subscribeNewsletter(
          platform: $platform
          platformId: $platformId
          emailAddress: $emailAddress
        ) {
          success
        }
      }
    `
  );

  useEffect(() => {
    setLog((p) => `* App started\n${p}`);
  }, []);

  return (
    <Container>
      <pre
        style={{
          fontSize: "12px",
          color: "white",
          background: "black",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        {log}
        <br />
        error: {JSON.stringify(error)}
        <br />
        response: {JSON.stringify(data)}
      </pre>
      {subscribed ? (
        <div>
          <Title>Subscription Successful</Title>
          <Body style={{ marginTop: 20 }}>
            Thank you for subscribing!
            <br />
            An AmazeVR newsletter will be on its way to your inbox soon.
          </Body>
        </div>
      ) : (
        <Form
          onFinish={async (values) => {
            setLog((p) => `* Form submit ${values.emailAddress}\n${p}`);

            if (!validator.isEmail(values.emailAddress || "")) {
              setLog((p) => `* Invalid email ${values.emailAddress}\n${p}`);
              alert("Please enter a valid email address");
              return;
            }

            if (artistRelease.releaseAt) {
              setLog((p) => `* Submit artist ${values.emailAddress}\n${p}`);
              await subscribeArtist({
                variables: {
                  artistId,
                  platform,
                  platformId,
                  emailAddress: values.emailAddress,
                },
              });
            } else {
              setLog((p) => `* Submit news ${values.emailAddress}\n${p}`);
              await subscribe({
                variables: {
                  artistId,
                  platform,
                  platformId,
                  emailAddress: values.emailAddress,
                },
              });
            }

            setSubscribed(true);
            setLog((p) => `Sent ${values.emailAddress}\n${p}`);

            alert("Thank you for subscribing!");
          }}
        >
          <Title
            style={{
              fontSize: "26px",
              fontWeight: 600,
            }}
          >
            {artistRelease.releaseAt ? (
              <>
                Do you want to subscribe to the latest news from{" "}
                {artistRelease.name} on AmazeVR?
              </>
            ) : (
              <>Will you subscribe to the AmazeVR newsletter?</>
            )}
          </Title>
          <Body>
            Your email address
            <br />
            <Form.Item name="emailAddress" required>
              <Input
                type="email"
                style={{
                  border: 0,
                  outline: 0,
                  fontSize: "20px",
                  height: 56,
                  width: "100%",
                  marginTop: 10,
                  padding: 5,
                  borderRadius: 0,
                  fontFamily:
                    "AmazeSofiaProV7 helvetica neue helvetica, arial, sans-serif",
                }}
                placeholder="Enter your email"
              />
            </Form.Item>
          </Body>

          <Body>You will receive email marketing from us.</Body>
          <div style={{ paddingTop: 10 }}>
            <SubscribeButton
              htmlType="submit"
              loading={false}
              onClick={() =>
                setLog((p) => `* submit button - event click\n${p}`)
              }
              onDoubleClick={() =>
                setLog((p) => `* submit button - event dblclick\n${p}`)
              }
              onMouseDown={() =>
                setLog((p) => `* submit button - event mousedown\n${p}`)
              }
              onMouseUp={() =>
                setLog((p) => `* submit button - event mouseup\n${p}`)
              }
              // onMouseMove={() =>
              //   setLog((p) => `* submit button - event mousemove\n${p}`)
              // }
              onMouseOver={() =>
                setLog((p) => `* submit button - event mouseover\n${p}`)
              }
              onMouseOut={() =>
                setLog((p) => `* submit button - event mouseout\n${p}`)
              }
              onMouseEnter={() =>
                setLog((p) => `* submit button - event mouseenter\n${p}`)
              }
              onMouseLeave={() =>
                setLog((p) => `* submit button - event mouseleave\n${p}`)
              }
              onContextMenu={() =>
                setLog((p) => `* submit button - event contextmenu\n${p}`)
              }
              onWheel={() =>
                setLog((p) => `* submit button - event wheel\n${p}`)
              }
              onTouchStart={() =>
                setLog((p) => `* submit button - event touchstart\n${p}`)
              }
              onTouchEnd={() =>
                setLog((p) => `* submit button - event touchend\n${p}`)
              }
              onTouchMove={() =>
                setLog((p) => `* submit button - event touchmove\n${p}`)
              }
              onTouchCancel={() =>
                setLog((p) => `* submit button - event touchcancel\n${p}`)
              }
            >
              Subscribe
            </SubscribeButton>
          </div>
        </Form>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 18px 20px 0 20px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  margin-bottom: 8px;
  color: #ececec;
`;

const Body = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  margin-bottom: 24px;
  color: #ececec;
`;

const Link = styled.div<{ loading: boolean }>`
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  border-bottom: 1px #ececec solid;
  cursor: pointer;

  ${({ loading }) => loading && "opacity: 0.5; border: none; "}
`;

// const Button = styled.div<{ loading: boolean }>`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 16px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.56;
//   letter-spacing: normal;
//   cursor: pointer;
//   background-color: #ececec;
//   border-radius: 2px;
//   color: #131416;
//   height: 56px;
//   width: 100%;

//   ${({ loading }) => loading && "opacity: 0.5; border: none; "}
// `;

const SubscribeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  cursor: pointer;
  background-color: #ececec;
  border-radius: 2px;
  color: #131416;
  height: 56px;
  width: 100%;

  &:active {
    transition: none;
    opacity: 0.5;
  }
`;
