import styled from "styled-components";

import logo from "./logo.png";

export const GNB = () => (
  <Wrapper>
    <img src={logo} height={14} width={126} />
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;
