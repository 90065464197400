import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { FC, ReactNode } from "react";

const MAIL_API_ENDPOINT = process.env.REACT_APP_MAIL_API_ENDPOINT as string;

const client = new ApolloClient({
  uri: MAIL_API_ENDPOINT,
  cache: new InMemoryCache(),
});

export const Wrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
