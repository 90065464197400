import { gql, useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { useMemo } from "react";

export const useArtistRelease = (artistId?: string) => {
  const { data, error, loading } = useQuery<{
    artistRelease: {
      name: string;
      earlyAccessAt: string;
      earlyAccessEmailEnabled: Boolean;
      releaseAt: string
    };
  }>(gql`
      query ArtistRelease($artistId: ID!) {
        artistRelease(artistId: $artistId) {
          name
          earlyAccessAt
          earlyAccessEmailEnabled
          releaseAt
        }
      }
    `, {
    variables: {
      artistId,
    },
    skip: !artistId,
  });

  const artistRelease = data?.artistRelease;

  const isEarlyAccess = useMemo(() => {
    if (!artistRelease?.earlyAccessAt) {
      return null;
    }

    const earlyAccessAt = moment(Number(artistRelease.earlyAccessAt));

    if (artistRelease.earlyAccessEmailEnabled) {
      return moment().isBefore(earlyAccessAt);
    }

    return false;
  }, [artistRelease]);

  const comingSoon = useMemo(() => {
    if (!artistRelease?.releaseAt) {
      return null;
    }

    const releaseAt = moment(Number(artistRelease.releaseAt));

    if (!artistRelease.earlyAccessEmailEnabled) {
      return moment().isBefore(releaseAt);
    }

    return false;
  }, [artistRelease]);


  return useMemo(() => ({
    ...artistRelease,
    isEarlyAccess,
    comingSoon,
    loading,
    error
  }), [artistRelease, comingSoon, error, isEarlyAccess, loading]);
};
